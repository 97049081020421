import * as React from "react"
import styled from "styled-components"
import ProxyLogo from './../../assets/iProxy_logo_forDarkBG.svg'
import {ButtonAtom} from "../atoms/ButtonAtom";

export const FooterBlock = () => {
    return (
        <Wrapper>
            <div className={'logo'}>
                <img src={ProxyLogo} alt="iProxy.online"/>
            </div>
            <div>
                <ButtonAtom isClear title={'Go to main Website'} onClick={() => window.location.href = '/'}/>
            </div>
        </Wrapper>
    )
}


const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: #0D0E3C;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 25px 10px;
  
  .logo{
    margin-bottom: 30px;
  }
  
`;
