import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;800&display=swap');

  body {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    background-size: cover;
    background: #FFFFFF linear-gradient(143.33deg, #131771 1.55%, #050848 85.05%) no-repeat fixed;
  }
`
