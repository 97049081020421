import * as React from "react"
import styled from "styled-components"
import {GlobalStyle} from "../components/globalCss";
import {PartnersBlock} from "../components/blocks/PartnersBlock";
import {MainTopBlock} from "../components/blocks/MainTopBlock";
import {FooterBlock} from "../components/blocks/FooterBlock";
import {PoolElement} from "../components/elements/PoolElement";
import {useEffect, useState} from "react";
import {ThankYou} from "../components/blocks/Thankyou";


const IndexPage = () => {
  // modes: main|run|end
  const [mode, setMode] = useState('main')
  const [landData, setLandData] = useState(null);
  const [userData, setUserData] = useState({
      email: null,
      telegram: null,
      meta: null
  })

    const [promo, setUserPromo] = useState(null);

    useEffect(() => {
        fetch(`${process.env.GATSBY_API_URL  || 'https://new-cms.iproxy.online/api'}/conference-landing?populate=*,partners.partnerImage`, {  method: 'GET'})
            .then(response => response.json())
            .then(result => {
                setLandData(result?.data?.attributes);
            })
            .catch(error => console.log('error', error));

    }, [])



  return (
    <>
        <Wrapper>

            <GlobalStyle/>
            {
                mode === 'main'
                    ? (
                        <>
                            <MainTopBlock
                                title={landData?.title || '-'}
                                description={landData?.description || '-'}
                                onRegisterClick={(user) => {
                                    setUserData({
                                        ...userData,
                                        ['email']: user.email,
                                        ['telegram']: user.telegram
                                    })
                                    setMode('run')
                                }}
                            />
                            <PartnersBlock partners={landData?.partners}/>
                        </>
                ) : null
            }


            {
                mode === 'run' ? <PoolElement
                    onReady={(ans) => {
                        const myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");

                        fetch(`${process.env.GATSBY_API_URL || 'https://new-cms.iproxy.online/api'}/conference-db`, {
                            method: 'POST',
                            redirect: 'follow',
                            headers: myHeaders,
                            body: JSON.stringify({
                                ...userData,
                                ['meta']: ans
                            })})
                            .then(response => response.json())
                            .then(result => {
                                if(result?.status == 'ok'){
                                    setUserPromo(result?.data?.meta?.promoCode)
                                }
                                setMode('end');
                            })
                            .catch(error => console.log('error', error));
                    }}
                    config={landData?.poolConfig}/> : null
            }


            {
                mode === 'end' ? <ThankYou promo={promo}/> : null
            }

        </Wrapper>
        {
            mode !== 'end' && mode !== 'run' ? <FooterBlock  /> : null
        }
    </>
)
}

export default IndexPage

export const Head = () => <title>iProxy.online SPECIAL OFFER FOR Affiliate World Asia Attendees!</title>

const Wrapper = styled.main`
  max-width: 800px;
  margin: 20px auto;
  padding: 10px;
  box-sizing:border-box;
`;
