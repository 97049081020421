import * as React from "react"
import styled from "styled-components"

export const ButtonAtom = ({title, isClear = false, fullWidth = false, onClick = () => {}, disiabled = false, children}) => {
    return (
        <Wrapper isClear={isClear} disiabled={disiabled} fullWidth={fullWidth} onClick={() => disiabled ? '' : onClick()}>
            {title}
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: ${(props) => props.fullWidth ? '100%' : ' fit-content'};
  display: flex;
  flex-direction: row;
  justify-content: center;w
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  height: 56px;
  background: ${(props) => props.isClear ? 'transparent' : props?.disiabled ? '#202142' : '#FF2F5B'};
  box-shadow: ${(props) => props.isClear || props.disiabled ? 'none' : '0 16px 16px rgba(255, 47, 91, 0.12)'};
  border-radius: 12px;
  cursor: pointer;
  border: ${(props) => props.isClear ? '1px solid #FF2F5B' : 'none'};

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  box-sizing: border-box;
  
  :hover{
    background: ${(props) => props.isClear ? 'transparent' : props?.disiabled ? '#202142' : '#CD163D'};
    box-shadow: ${(props) => props.isClear || props.disiabled ? 'none' : '0px 16px 16px rgba(255, 47, 91, 0.12)'};
    opacity: ${(props) => props.isClear ? 0.7 : '1'};
  }
  
  
  
  
  transition: .2s;
`;
