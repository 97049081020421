import * as React from "react"
import styled from "styled-components"

export const InputAtom = ({children, placeholder, error = null, onChange = () => {}}) => {
    return (
        <Wrapper>
            <InputComponent
                type="text"
                onChange={({target}) => onChange(target?.value)}
                placeholder={placeholder}/>
            <div className={'error-msg'}>
                {error}
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
 .error-msg{
   font-weight: 400;
   font-size: 14px;
   line-height: 14px;
   color: #FF4343;
   flex: none;
   order: 1;
   flex-grow: 0;
   margin-top: 10px;
 }
`;


const InputComponent = styled.input`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 10px;
  color: white;
  min-width: 296px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  border: 1px solid #3739A2;
  border-radius: 8px;
  outline: none;
  background-color: transparent;  

  ::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5F61AD;
  }

  :focus {
    border: 1px solid #595DFF;
  }

`;
