import * as React from "react"
import styled from "styled-components"
import {InputAtom} from "../atoms/InputAtom";
import {ButtonAtom} from "../atoms/ButtonAtom";
import ProxyLogo from './../../assets/iProxy_logo_forDarkBG.svg'
import {useState} from "react";
import * as EmailValidator from 'email-validator';


export const MainTopBlock = ({onRegisterClick, title, description}) => {
    const [user, setUser] = useState({
        email: null,
        telegram: null
    });

    const [emailError, setEmailError] = useState(false);
    return (
        <Wrapper>
            <div className={'logo'}>
                <img src={ProxyLogo} alt="iProxy.online"/>
            </div>

            <div className="title">{title}</div>

            <div className={'description'}>{description}</div>

            <div className={'form-container'}>
                <div className={'item'}>
                    <InputAtom

                        error={emailError ? 'Email is incorrect' : null}
                        placeholder={'Enter your E-mail'}
                        onChange={(val) => {
                            setEmailError(false);
                            setUser({...user, ['email']: val});
                        }}/>
                </div>
                <div className={'item'}>
                    <InputAtom
                        placeholder={'Enter your Telegram (Optional)'}
                        onChange={(val) => setUser({...user, ['telegram']: val})}/>
                </div>
                <div className={'signup-cont'}>
                    <ButtonAtom  fullWidth title={'Registration'} onClick={() => {
                        if(user.email != null && EmailValidator.validate(user.email)){
                            onRegisterClick(user)
                        } else {
                            setEmailError(true)
                        }
                    }}/>
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin-bottom: 80px;
    .logo{
      margin-bottom: 20px;
    }
  
  .signup-cont{
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  
    .title{
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      color: #fff;
      letter-spacing: 0.01em;
      margin-bottom: 15px;
    }
    .description{
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: white;
    }
  
  .form-container{
    margin-top: 40px;
    .item{
      margin-bottom: 35px;
    }
  }
`;
